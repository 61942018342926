import { CommonModule } from '@angular/common'
import { Component, Input, OnInit, inject } from '@angular/core'
import { Router, RouterModule } from '@angular/router'
import { AuthStateService } from '@modules/auth/states/auth-state.service'
import { NavbarLogoComponent } from '@modules/navbar/components/navbar-logo/navbar-logo.component'
import { AdminRoutes, getAdminRoutes } from '@pages/admin/admin.routes'
import { TuiHintModule } from '@taiga-ui/core'
import { Hotkey, HotkeysService } from 'angular2-hotkeys'

@Component({
    selector: 'app-admin-sidebar',
    standalone: true,
    templateUrl: './admin-sidebar.component.html',
    styleUrls: ['./admin-sidebar.component.scss'],
    imports: [CommonModule, RouterModule, NavbarLogoComponent, TuiHintModule],
})
export class AdminSidebarComponent implements OnInit {
    authStateService = inject(AuthStateService)
    private router = inject(Router)
    private hotkeysService = inject(HotkeysService)

    @Input() isSmall = false
    @Input() isDark = false

    readonly adminRoutes: AdminRoutes = getAdminRoutes()
    private hotkeys: Hotkey[] = []

    ngOnInit() {
        this.hotkeys = this.getRouteShortcuts()
        this.hotkeysService.add(this.hotkeys)
    }

    ngOnDestroy(): void {
        this.hotkeysService.remove(this.hotkeys)
    }

    private getRouteShortcuts() {
        const sidebarRoutes = [
            this.adminRoutes.index.path,
            this.adminRoutes.users.path,
            this.adminRoutes.feedbacks.path,
            this.adminRoutes.plans.path,
            this.adminRoutes.organizations.path,
            this.adminRoutes.releaseNotes.path,
        ]

        return sidebarRoutes.map((route, i) => {
            return new Hotkey(String(i + 1), (event: KeyboardEvent): boolean => {
                this.router.navigate([route])
                return false
            })
        })
    }
}
