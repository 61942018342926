import { Route } from '@angular/router'
import { AuthGuard } from '@core/guards/auth.guard'
import { PageLayout } from '@modules/layout/page-layout.enum'
import { setLayout } from '@modules/layout/set-layout.resolver'

export type AdvertiserRoutes = {
    index: Route
    advertise: Route
    profile: Route
}

export function getAdvertiserRoutes(): AdvertiserRoutes {
    return {
        index: {
            path: 'advertiser',
            title: 'Ads Dashboard',
            resolve: { layout: setLayout(PageLayout.Advertiser) },
            canActivate: [AuthGuard],
            loadComponent: () =>
                import('./advertiser-home/advertiser-home.component').then(
                    (m) => m.AdvertiserHomeComponent,
                ),
        },
        advertise: {
            path: 'advertiser/advertise',
            title: 'Advertise',
            resolve: { layout: setLayout(PageLayout.Advertiser) },
            canActivate: [AuthGuard],
            loadComponent: () =>
                import('./publish-advertisement/publish-advertisement.component').then(
                    (m) => m.PublishAdvertisementComponent,
                ),
        },
        profile: {
            path: 'advertiser/profile',
            title: 'Profile',
            resolve: { layout: setLayout(PageLayout.Advertiser) },
            canActivate: [AuthGuard],
            loadComponent: () =>
                import('@pages/dashboard/profile/profile.component').then(
                    (m) => m.ProfileComponent,
                ),
        },
    }
}
