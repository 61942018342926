<form
    class="min-w-screen-sm"
    w-full
    [formGroup]="feedbackStateService.feedbackForm"
    (ngSubmit)="onSubmit()"
>
    <h3 class="text-3xl font-bold">Send us your feedback</h3>
    <p class="mt-2 text-slate-500">We read and listen to all of it!</p>

    <div tuiGroup class="mt-8" [collapsed]="true">
        @for (option of feedbackStateService.feedbackOptions; track option) {
            <tui-radio-block
                contentAlign="right"
                formControlName="feedbackType"
                size="l"
                [hideRadio]="true"
                [item]="option"
            >
                {{ option.feedbackType }}
            </tui-radio-block>
        }
    </div>
    @if (feedbackTypeControl.value.value == 'feature') {
        <a (click)="closeDialog()" routerLink="dashboard/roadmap" class="daisyUI link text-blue-500"
            >See all feature list</a
        >
    }
    <div class="mt-4">
        <tui-textarea formControlName="feedbackText" tuiAutoFocus> Your comments... </tui-textarea>

        @if (feedbackStateService.hasTextareaMinLengthError) {
            <small class="text-sm text-error"> At least 15 characters required... </small>
        }
    </div>

    <div class="mt-4">
        <label class="mb-2 block">
            Attachments
            <span class="text-slate-500">(Optional)</span>
        </label>
        <tui-input-files
            accept="image/*"
            formControlName="attachments"
            [multiple]="true"
            (ngModelChange)="fileInputControl.markAsTouched()"
            (reject)="onFileReject($event)"
        ></tui-input-files>

        <tui-files class="tui-space_top-1">
            @for (file of fileInputControl.valueChanges | async; track file) {
                <tui-file
                    [file]="file"
                    [showDelete]="fileInputControl.enabled"
                    (removed)="removeFile(file)"
                ></tui-file>
            }

            @for (file of rejectedFiles; track file) {
                <tui-file
                    state="error"
                    [file]="file"
                    [showDelete]="fileInputControl.enabled"
                    (removed)="clearRejected(file)"
                ></tui-file>
            }
        </tui-files>

        <tui-error
            [error]="['maxLength'] | tuiFieldError | async"
            [formControl]="fileInputControl"
        ></tui-error>
    </div>

    <p class="mt-4">
        <button tuiButton [disabled]="feedbackStateService.feedbackForm.invalid" type="submit">
            Submit
        </button>
    </p>
</form>
