<div
    *tuiLet="appStateService.select('sidebarVisible') | async as sidebarOpen"
    class="flex h-screen"
>
    <aside
        class="fixed left-0 top-0 h-screen border-r border-slate-300 bg-base-200"
        [ngClass]="{
            'w-48': sidebarOpen,
            'w-16': !sidebarOpen,
        }"
    >
        <app-admin-sidebar
            [isDark]="themeService.isDark$ | async"
            [isSmall]="!sidebarOpen"
        ></app-admin-sidebar>
    </aside>

    <div
        class="flex-1 bg-base-200"
        [ngClass]="{
            'ml-48': sidebarOpen,
            'ml-16': !sidebarOpen,
        }"
    >
        <nav class="sticky top-0 z-30">
            <app-admin-navbar class="border-b border-solid border-slate-300"></app-admin-navbar>
        </nav>

        <main class="block w-full bg-base-200 px-4 pb-20">
            <ng-content></ng-content>
        </main>
    </div>
</div>
