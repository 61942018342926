import { CommonModule } from '@angular/common'
import { Component, inject } from '@angular/core'
import { ReleaseNote } from '@modules/update/models/release-note.model'
import { ReleaseNoteListStateService } from '@modules/update/states/release-note-list-state.service'
import { TuiDialogContext } from '@taiga-ui/core'
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus'
import { ReleaseNoteCardComponent } from '../release-note-card/release-note-card.component'

@Component({
    selector: 'app-release-note-dialog',
    standalone: true,
    templateUrl: './release-note-dialog.component.html',
    styleUrl: './release-note-dialog.component.scss',
    imports: [ReleaseNoteCardComponent, CommonModule],
})
export class ReleaseNoteDialogComponent {
    releaseNoteListStateService = inject(ReleaseNoteListStateService)
    private readonly context =
        inject<TuiDialogContext<ReleaseNote, ReleaseNote>>(POLYMORPHEUS_CONTEXT)

    get releaseNote(): ReleaseNote | null {
        return this.context.data
    }
}
