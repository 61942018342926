<div *tuiLet="appStateService.select('sidebarVisible') | async as sidebarOpen" class="flex">
    <aside
        class="fixed left-0 top-0 z-20 min-h-full border-r border-slate-300 bg-base-200"
        [ngClass]="{
            'w-48': sidebarOpen,
            'w-16': !sidebarOpen,
        }"
    >
        <app-user-sidebar [isDark]="isDark$ | async" [isSmall]="!sidebarOpen"></app-user-sidebar>
    </aside>

    <div
        *tuiLet="proPlan$ | async as proPlan"
        class="flex-1 bg-base-200"
        [ngClass]="{
            'ml-48': sidebarOpen,
            'ml-16': !sidebarOpen,
        }"
    >
        <nav class="sticky top-0 z-30">
            <app-user-navbar class="border-b border-solid border-slate-300"></app-user-navbar>

            <!-- <div class="bg-base-200 px-4">
                <tui-breadcrumbs
                    *ngIf="authStateService.isHighLevelUser()"
                    size="l"
                    class="text-slate-500"
                >
                    <ng-container *ngFor="let item of items">
                        <a
                            class="inline-flex items-center gap-2"
                            *tuiItem
                            tuiLink
                            [routerLink]="item.routerLink"
                        >
                            <i *ngIf="item.icon" class="text-sm" [ngClass]="item.icon"></i>
                            {{ item.label }}
                        </a>
                    </ng-container>
                </tui-breadcrumbs>
            </div> -->
        </nav>

        <div class="flex">
            <main class="block w-full bg-base-200 p-4">
                <ng-content></ng-content>
            </main>

            <!-- ad side -->
            @if (!proPlan && showAds) {
                <div class="w-[160px]">
                    <div
                        class="flex h-[600px] w-[160px] items-center justify-center bg-base-300"
                        style="position: fixed; bottom: 98px"
                    >
                        (160 x 600)
                    </div>
                </div>
            }
        </div>

        <!-- ad bottom and mobile -->
        @if (!proPlan && showAds) {
            <div class="fixed bottom-0 right-0 z-0">
                <div
                    class="flex h-[50px] w-[320px] items-center justify-center bg-base-300 sm:h-[98px] sm:w-[720px]"
                >
                    (720x98)
                </div>
            </div>
        }
    </div>
</div>
