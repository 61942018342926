@if (visible) {
    <div>
        <div class="blur-container">
            <img
                class="rotate-screen h-16 w-16"
                src="assets/images/icons/rotate-icon.svg"
                alt="Rotate Screen"
            />
        </div>
    </div>
}
