<section class="flex items-center">
    <div
        class="embla w-full overflow-hidden"
        emblaCarousel
        (mouseenter)="halt()"
        (mouseleave)="unHalt()"
        [options]="options"
    >
        <div class="embla__container flex">
            @for (update of updates; track update) {
                <div class="embla__slide min-w-0">
                    <div (click)="view(update)" class="font-semibold hover:cursor-pointer">
                        {{ update.title }}
                    </div>
                </div>
            }
        </div>
    </div>
</section>
