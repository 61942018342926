import { UserPermissionKeys } from '@modules/user/model/user.interface'
import { dashboardRoutes } from '@pages/dashboard/dashboard.routes'

export type SidebarMenuItem = {
    label: string
    bxIcon: string
    route: string
    permissionKey: UserPermissionKeys | ''
    shortcut?: string
}

const homeMenu: SidebarMenuItem = {
    permissionKey: '',
    label: 'Home',
    bxIcon: 'bx-home',
    route: dashboardRoutes.index.path,
}

const contentMenu: SidebarMenuItem = {
    permissionKey: 'contents',
    label: 'Content',
    bxIcon: 'bx-table',
    route: dashboardRoutes.content.path,
}

const researchMenu: SidebarMenuItem = {
    permissionKey: 'research',
    label: 'Research',
    bxIcon: 'bx-hash',
    route: dashboardRoutes.research.path,
}

const cerpWriterMenu: SidebarMenuItem = {
    permissionKey: '',
    label: 'Cerp Writer',
    bxIcon: 'bxs-pencil',
    route: dashboardRoutes.articleEditor.path,
}

const financialMenu: SidebarMenuItem = {
    permissionKey: 'finance',
    label: 'Financial',
    bxIcon: 'bx-line-chart',
    route: dashboardRoutes.financialV2.path,
}

const paymentMenu: SidebarMenuItem = {
    permissionKey: '',
    label: 'My Payments',
    bxIcon: 'bxs-credit-card',
    route: dashboardRoutes.payment.path,
}

const progressMenu: SidebarMenuItem = {
    permissionKey: '',
    label: 'Progress',
    bxIcon: 'bx-calendar',
    route: dashboardRoutes.progress.path,
}

const queueManagementMenu: SidebarMenuItem = {
    permissionKey: '',
    label: 'Queue',
    bxIcon: 'bx-add-to-queue',
    route: dashboardRoutes.queueManagement.path,
}

const queueMenu: SidebarMenuItem = {
    permissionKey: '',
    label: 'Queue',
    bxIcon: 'bx-add-to-queue',
    route: dashboardRoutes.queue.path,
}

const usersAndTeamsMenu: SidebarMenuItem = {
    permissionKey: 'users',
    label: 'Users & Teams',
    bxIcon: 'bxs-user-account',
    route: dashboardRoutes.usersManagement.path,
}

const forumMenu: SidebarMenuItem = {
    permissionKey: '',
    label: 'Forum',
    bxIcon: 'bxs-user-voice',
    route: dashboardRoutes.forum.path,
}

const dailyUpdatesMenu: SidebarMenuItem = {
    permissionKey: '',
    label: 'Daily Updates',
    bxIcon: 'bxs-news',
    route: dashboardRoutes.journals.path,
    shortcut: '0',
}

const chatMenu: SidebarMenuItem = {
    permissionKey: '',
    label: 'Chat',
    bxIcon: 'bxs-message-square-detail',
    route: dashboardRoutes.chat.path,
}

const socialMenu: SidebarMenuItem = {
    permissionKey: '',
    label: 'Social',
    bxIcon: 'bx bxs-comment-dots',
    route: dashboardRoutes.social.path,
}

export const highLevelUserMenu: SidebarMenuItem[] = [
    homeMenu,
    contentMenu,
    researchMenu,
    cerpWriterMenu,
    progressMenu,
    queueManagementMenu,
    chatMenu,
    socialMenu,
]

export const lowLevelUserMenu: SidebarMenuItem[] = [
    queueMenu,
    researchMenu,
    cerpWriterMenu,
    paymentMenu,
    chatMenu,
    socialMenu,
]

export const ownerUserMenu: SidebarMenuItem[] = [
    homeMenu,
    contentMenu,
    researchMenu,
    cerpWriterMenu,
    financialMenu,
    progressMenu,
    queueManagementMenu,
    usersAndTeamsMenu,
    forumMenu,
    dailyUpdatesMenu,
    chatMenu,
    socialMenu,
]
