import { Directive, ElementRef, Input, OnDestroy, OnInit, inject } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { Subscription, filter } from 'rxjs'

@Directive({
    selector: '[customRouteActive]',
    standalone: true,
})
export class CustomRouterActiveDirective implements OnInit, OnDestroy {
    private el = inject(ElementRef)
    private router = inject(Router)

    @Input('customRouteActive') link: string
    private routerSubscription: Subscription
    isActive = false

    ngOnInit() {
        this.routerSubscription = this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                const currentRoute = this.router.url.split('?')[0] // Remove query parameters
                this.isActive = currentRoute === `/${this.link}`

                this.el.nativeElement.classList.toggle('active', this.isActive)
            })
    }

    ngOnDestroy() {
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe()
        }
    }
}
