import { Component, Injector, Input, OnChanges, ViewChild, inject } from '@angular/core'
import { ReleaseNoteDialogComponent } from '@modules/update/components/release-note-dialog/release-note-dialog.component'
import { ReleaseNote } from '@modules/update/models/release-note.model'
import { TuiDialogService } from '@taiga-ui/core'
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus'
import { EmblaCarouselDirective, EmblaCarouselType } from 'embla-carousel-angular'
import { BehaviorSubject, combineLatest, filter, interval } from 'rxjs'

@Component({
    selector: 'app-release-note-carousel',
    standalone: true,
    imports: [EmblaCarouselDirective],
    templateUrl: './release-note-carousel.component.html',
    styleUrl: './release-note-carousel.component.scss',
})
export class ReleaseNoteCarouselComponent implements OnChanges {
    private readonly dialogs = inject(TuiDialogService)
    private readonly injector = inject(Injector)

    @Input() releaseNotes: ReleaseNote[] | null = null
    @ViewChild(EmblaCarouselDirective) emblaRef!: EmblaCarouselDirective

    options = {
        loop: true,
    }
    updates = []

    private emblaApi?: EmblaCarouselType
    private haltSubject = new BehaviorSubject<boolean>(false)

    ngOnChanges(): void {
        this.updates = this.releaseNotes.slice(0, 3)
    }

    ngAfterViewInit() {
        this.emblaApi = this.emblaRef.emblaApi

        combineLatest([interval(8000), this.haltSubject.asObservable()])
            .pipe(filter(([_, halt]) => !halt))
            .subscribe(() => {
                if (this.emblaApi) {
                    this.emblaApi.scrollNext()
                }
            })
    }

    halt() {
        this.haltSubject.next(true)
    }

    unHalt() {
        this.haltSubject.next(false)
    }

    view(update: ReleaseNote[]) {
        const dialog = this.dialogs.open<ReleaseNote>(
            new PolymorpheusComponent(ReleaseNoteDialogComponent, this.injector),
            {
                data: update,
                dismissible: true,
                size: 'auto',
            },
        )

        dialog.subscribe({
            next: (data) => {
                console.info(`Dialog emitted data = ${data}`)
            },
            complete: () => {
                console.info('Dialog closed')
            },
        })
    }
}
