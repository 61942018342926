import { Component, Input } from '@angular/core'

@Component({
    selector: 'app-navbar-logo',
    standalone: true,
    imports: [],
    templateUrl: './navbar-logo.component.html',
    styleUrls: ['./navbar-logo.component.scss'],
})
export class NavbarLogoComponent {
    @Input() isSmall = false
    @Input() isDark = false
}
