<form class="w-[700px]" [formGroup]="journalForm" (ngSubmit)="onSubmit()">
    <div class="mb-4 w-full">
        <div class="mb-4 text-xl font-bold">Daily Updates</div>
        <div class="mt-2 rounded p-4 shadow-lg">
            <div class="badge badge-neutral">Description</div>
            <div class="mt-2 text-sm">
                <ul class="list-none">
                    <li>What did you do today & how long it took?</li>
                    <li>How many hours you worked?</li>
                </ul>
            </div>
            <tui-textarea
                id="description"
                formControlName="description"
                tuiAutoFocus
                class="mt-2 w-full rounded-sm focus:border-blue-500 focus:outline-none"
            >
                Write your response here...
            </tui-textarea>
        </div>

        <div class="mt-2 rounded p-4 shadow-lg">
            <div class="badge badge-neutral">Challenges</div>
            <div class="mt-2 text-sm">
                <ul class="list-none">
                    <li>
                        What were the biggest challenges you encountered, and how did you handle
                        them?
                    </li>
                    <li>
                        What aspects went particularly well, and what can you learn from them for
                        the future?
                    </li>
                    <li>What concrete steps are you taking to address the remaining challenges?</li>
                </ul>
            </div>
            <tui-textarea
                id="challenges"
                formControlName="challenges"
                class="mt-2 w-full rounded-sm focus:border-blue-500 focus:outline-none"
            >
                Write your response here...
            </tui-textarea>
        </div>

        <div class="mt-2 rounded p-4 shadow-lg">
            <div class="badge badge-neutral">Questions</div>
            <div class="mt-2 text-sm">
                <ul class="list-none">
                    <li>
                        Any question to better understand your needs and provide the most helpful
                        responses?
                    </li>
                    <li>Is there anything else I can do to assist you today?</li>
                    <li>Questions about updates on our progress or capabilities?</li>
                </ul>
            </div>
            <tui-textarea
                id="questions"
                formControlName="questions"
                class="mt-2 w-full rounded-sm focus:border-blue-500 focus:outline-none"
            >
                Write your response here...
            </tui-textarea>
        </div>
    </div>
    <div class="mt-4">
        <button tuiButton [disabled]="journalForm.invalid" type="submit" appearance="primary">
            Submit
        </button>
    </div>
</form>
