import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Output, inject } from '@angular/core'
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms'
import { RouterLink } from '@angular/router'
import { AlertService } from '@core/services/alert.service'
import { TaigaUiImports } from '@core/ui/taiga-ui-imports'
import { FeedbackStateService } from '@modules/feedback/states/feedback-state.service'
import { TuiFileLike } from '@taiga-ui/kit'

@Component({
    selector: 'app-feedback-dialog',
    standalone: true,
    imports: [CommonModule, FormsModule, ReactiveFormsModule, ...TaigaUiImports, RouterLink],
    templateUrl: './feedback-dialog.component.html',
    styleUrls: ['./feedback-dialog.component.scss'],
})
export class FeedbackDialogComponent {
    private alertService = inject(AlertService)
    feedbackStateService = inject(FeedbackStateService)

    @Output() done = new EventEmitter<void>()

    rejectedFiles: readonly TuiFileLike[] = []

    get fileInputControl() {
        return this.feedbackStateService.feedbackForm.get('attachments') as UntypedFormControl
    }

    get feedbackTypeControl() {
        return this.feedbackStateService.feedbackForm.get('feedbackType') as UntypedFormControl
    }

    onSubmit() {
        this.feedbackStateService.submitFeedback().subscribe({
            next: () => {
                this.alertService.success('Thank you for your feedback!')
                this.done.next()
            },
            error: (err) => {
                this.alertService.error(err ?? 'Something went wrong. Please try again.')
            },
        })
    }

    onFileReject(files: TuiFileLike | readonly TuiFileLike[]): void {
        this.rejectedFiles = [...this.rejectedFiles, ...(files as TuiFileLike[])]
    }

    removeFile({ name }: File): void {
        this.fileInputControl.setValue(
            this.fileInputControl.value?.filter((current: File) => current.name !== name) ?? [],
        )
    }

    clearRejected({ name }: TuiFileLike): void {
        this.rejectedFiles = this.rejectedFiles.filter((rejected) => rejected.name !== name)
    }

    closeDialog() {
        this.done.next()
    }
}
