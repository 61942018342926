import { Component, inject } from '@angular/core'
import {
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms'
import { AlertService } from '@core/services/alert.service'
import { TaigaUiImports } from '@core/ui/taiga-ui-imports'
import { JournalStateService } from '@modules/journal/states/journal-state.service'
import { TuiDialogContext } from '@taiga-ui/core'
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus'

@Component({
    selector: 'app-daily-update-dialog',
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, ...TaigaUiImports],
    templateUrl: './daily-update-dialog.component.html',
    styleUrls: ['./daily-update-dialog.component.scss'],
})
export class DailyUpdateDialogComponent {
    private readonly context =
        inject<
            TuiDialogContext<
                undefined,
                {
                    logoutAfterSubmit: boolean
                }
            >
        >(POLYMORPHEUS_CONTEXT)
    private alertService = inject(AlertService)
    private journalStateService = inject(JournalStateService)

    journalForm: FormGroup = new FormGroup({
        description: new FormControl<string>('', [Validators.required]),
        challenges: new FormControl<string>('', [Validators.required]),
        questions: new FormControl<string>('', [Validators.required]),
    })

    onSubmit() {
        this.journalStateService
            .submitJournal(
                this.journalForm.value['description'],
                this.journalForm.value['challenges'],
                this.journalForm.value['questions'],
                this.context.data?.['logoutAfterSubmit'] ?? false,
            )
            .subscribe({
                next: () => {
                    this.alertService.success('Daily update saved')
                    this.journalForm.reset()
                },
                error: (err) => {
                    this.alertService.error(err ?? 'Something went wrong. Please try again.')
                },
            })
    }
}
