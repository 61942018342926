import { Component, Input } from '@angular/core'

@Component({
    selector: 'app-rotate-screen',
    standalone: true,
    imports: [],
    templateUrl: './rotate-screen.component.html',
    styleUrl: './rotate-screen.component.scss',
})
export class RotateScreenComponent {
    @Input() visible = false
}
