import { Component, Input, OnInit, inject } from '@angular/core'
import { RouterLink, RouterLinkActive } from '@angular/router'
import { AppStateService } from '@core/states/app-state.service'
import { TaigaUiImports } from '@core/ui/taiga-ui-imports'
import { AuthStateService } from '@modules/auth/states/auth-state.service'
import { Permission } from '@modules/user/model/user.interface'
import { AdvertiserRoutes, getAdvertiserRoutes } from '@pages/advertiser/advertiser.routes'
import { TuiDialogContext, TuiDialogService } from '@taiga-ui/core'
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus'
import { Subscription } from 'rxjs'
import { FeedbackDialogComponent } from '../../../feedback/components/feedback-dialog/feedback-dialog.component'
import { NavbarLogoComponent } from '../../../navbar/components/navbar-logo/navbar-logo.component'

@Component({
    selector: 'app-advertiser-sidebar',
    templateUrl: './advertiser-sidebar.component.html',
    styleUrls: ['./advertiser-sidebar.component.scss'],
    standalone: true,
    imports: [
        ...TaigaUiImports,
        RouterLink,
        RouterLinkActive,
        NavbarLogoComponent,
        FeedbackDialogComponent,
    ],
})
export class AdvertiserSidebarComponent implements OnInit {
    appStateService = inject(AppStateService)
    authStateService = inject(AuthStateService)
    private readonly dialogs = inject(TuiDialogService)

    @Input() isSmall = false
    @Input() isDark = false

    readonly advertiserRoutes: AdvertiserRoutes = getAdvertiserRoutes()
    readonly Permission = Permission
    private closeDialogSubscription: Subscription

    menu: {
        label: string
        bxIcon: string
        roles: string[]
        access: string[]
    }[] = []

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this.onDone()
    }

    onDone() {
        this.closeDialogSubscription?.unsubscribe()
    }

    showDialog(content: PolymorpheusContent<TuiDialogContext>) {
        this.closeDialogSubscription = this.dialogs.open<number>(content, { size: 'l' }).subscribe({
            next: (res) => {
                console.log('Dialog closed', res)
            },
        })
    }
}
