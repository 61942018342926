import { AsyncPipe, DatePipe, JsonPipe, NgClass } from '@angular/common'
import { Component, inject } from '@angular/core'
import { Router, RouterLink } from '@angular/router'
import { UseFallbackImageDirective } from '@core/directives/use-fallback-image.directive'
import { AppStateService } from '@core/states/app-state.service'
import { AuthStateService } from '@modules/auth/states/auth-state.service'
import { dashboardRoutes } from '@pages/dashboard/dashboard.routes'
import { getHomeRoutes } from '@pages/home/home.routes'
import { TuiLetModule } from '@taiga-ui/cdk'
import { map, timer } from 'rxjs'

@Component({
    selector: 'app-admin-navbar',
    standalone: true,
    imports: [
        NgClass,
        AsyncPipe,
        RouterLink,
        TuiLetModule,
        DatePipe,
        UseFallbackImageDirective,
        JsonPipe,
    ],
    templateUrl: './admin-navbar.component.html',
    styleUrls: ['./admin-navbar.component.scss'],
})
export class AdminNavbarComponent {
    private router = inject(Router)
    appStateService = inject(AppStateService)
    authStateService = inject(AuthStateService)

    readonly dashboardRoutes = dashboardRoutes
    date$ = timer(0, 1000).pipe(map(() => new Date()))

    logout() {
        this.authStateService.logout()
    }

    toggleSidebar() {
        const sidebarOpen = this.appStateService.getState().sidebarVisible
        this.appStateService.saveSidebarVisibility(!sidebarOpen)
    }

    toggleTheme() {
        this.appStateService.saveIsDarkMode(!this.appStateService.getState().isDarkMode)
    }
}
