<div class="navbar flex justify-between bg-base-100">
    <div>
        <a [routerLink]="['/']" class="btn btn-ghost text-xl normal-case">
            <img
                class="w-[175px]"
                [src]="
                    (themeService.isDark$ | async)
                        ? '/assets/images/cerp-logo-addon-dark.png'
                        : '/assets/images/cerp-logo-addon-light.png'
                "
            />
        </a>
    </div>
    @if (releaseNoteListStateService.select('releaseNote') | async; as releaseNotes) {
        <div>
            <div>
                <app-release-note-carousel
                    [releaseNotes]="releaseNotes"
                    class="text-center"
                ></app-release-note-carousel>
            </div>
        </div>
    }
    <div class="">
        <ul class="menu menu-horizontal flex px-1">
            <li>
                <a class [routerLink]="authRoutes.signIn.path"> Sign In </a>
            </li>
            <li>
                <a class [routerLink]="authRoutes.signUp.path"> Sign Up </a>
            </li>
        </ul>
    </div>
</div>
