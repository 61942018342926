import { Route } from '@angular/router'
import { AdminRoutes, getAdminRoutes } from '@pages/admin/admin.routes'
import { AdvertiserRoutes, getAdvertiserRoutes } from '@pages/advertiser/advertiser.routes'
import { AuthRoutes, getAuthRoutes } from '@pages/auth/auth.routes'
import { DashboardRoutes, dashboardRoutes } from '@pages/dashboard/dashboard.routes'
import { HomeRoutes, getHomeRoutes } from '@pages/home/home.routes'
import {
    NotFoundPageRoutes,
    getNotFoundPageRoutes,
} from '@pages/page-not-found/page-not-found.routes'
import { ShopRoutes, shopRoutes } from '@pages/shop/shop.routes'
import { PlanRoutes, planRoutes } from './pages/plans/plan.routes'

type GroupedRoutes = [
    HomeRoutes,
    AuthRoutes,
    DashboardRoutes,
    AdvertiserRoutes,
    PlanRoutes,
    ShopRoutes,
    AdminRoutes,
    // catch-all route must be last
    NotFoundPageRoutes,
]

const groupedRoutes: GroupedRoutes = [
    getHomeRoutes(),
    getAuthRoutes(),
    dashboardRoutes,
    getAdvertiserRoutes(),
    planRoutes,
    shopRoutes,
    getAdminRoutes(),
    getNotFoundPageRoutes(),
]

const flattenedRoutes: Route[] = []
for (const routeGroup of groupedRoutes) {
    for (const route of Object.values(routeGroup)) {
        flattenedRoutes.push(route)
    }
}

export const AppRoutes = flattenedRoutes
