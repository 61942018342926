import { DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common'
import {
    provideHttpClient,
    withInterceptors,
    withInterceptorsFromDi,
    withJsonpSupport,
    withXsrfConfiguration,
} from '@angular/common/http'
import { APP_INITIALIZER, enableProdMode, importProvidersFrom } from '@angular/core'
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser'
import { provideAnimations } from '@angular/platform-browser/animations'
import {
    PreloadAllModules,
    provideRouter,
    withComponentInputBinding,
    withInMemoryScrolling,
    withPreloading,
    withRouterConfig,
} from '@angular/router'
import { appInitializerFactory } from '@core/initializer/app-initializer'
import { TokenStorageService } from '@core/services/jwt/token-storage.service'
import { LocalStorageService } from '@core/services/local-storage.service'
import { AuthApiService } from '@modules/auth/services/auth-api.service'
import { AuthStateService } from '@modules/auth/states/auth-state.service'
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'
import { TUI_DATE_FORMAT, TUI_DATE_SEPARATOR } from '@taiga-ui/cdk'
import {
    TUI_ANIMATIONS_DURATION,
    TUI_SANITIZER,
    TuiAlertModule,
    TuiDialogModule,
    TuiRootModule,
} from '@taiga-ui/core'
import { NgDompurifySanitizer } from '@tinkoff/ng-dompurify'
import { HotkeyModule } from 'angular2-hotkeys'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import utc from 'dayjs/plugin/utc'
import { RecaptchaComponent } from 'ng-recaptcha'
import { provideNgxStripe } from 'ngx-stripe'
import { AppComponent } from './app/app.component'
import { AppRoutes } from './app/app.routes'
import { JwtInterceptorFn } from './app/core/interceptors/jwt-interceptor.interceptor'
import { environment } from './environments/environment'

dayjs.extend(utc)
dayjs.extend(duration)

if (environment.production) {
    enableProdMode()
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, TuiRootModule, TuiDialogModule, TuiAlertModule),
        provideHttpClient(
            withXsrfConfiguration({}),
            withJsonpSupport(),
            withInterceptors([JwtInterceptorFn /* serverErrorInterceptorFn */]),
            withInterceptorsFromDi(),
        ),
        provideRouter(
            AppRoutes,
            withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
            withRouterConfig({ onSameUrlNavigation: 'reload' }),
            withComponentInputBinding(),
            withPreloading(PreloadAllModules),
        ),
        importProvidersFrom([HotkeyModule.forRoot(), NgIdleKeepaliveModule.forRoot()]),
        provideAnimations(),
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            multi: true,
            deps: [AuthStateService, AuthApiService, TokenStorageService, LocalStorageService],
        },
        {
            provide: DATE_PIPE_DEFAULT_OPTIONS,
            useValue: { dateFormat: 'shortDate' },
        },
        {
            provide: TUI_ANIMATIONS_DURATION,
            useValue: 100, // Default value is 300
        },
        { provide: TUI_DATE_FORMAT, useValue: 'MDY' },
        { provide: TUI_DATE_SEPARATOR, useValue: '/' },
        {
            provide: TUI_SANITIZER,
            useClass: NgDompurifySanitizer,
        },
        provideNgxStripe(environment.STRIPE_PUBLIC_KEY),
    ],
}).catch((err) => console.error(err))

RecaptchaComponent.prototype.ngOnDestroy = function () {
    if (this.subscription) {
        this.subscription.unsubscribe()
    }
}
