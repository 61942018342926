<div class="max-h-screen overflow-y-auto md:h-full">
    <div class="p-2">
        <app-navbar-logo [isSmall]="isSmall" [isDark]="isDark"></app-navbar-logo>
    </div>

    <ul class="menu mb-16 rounded-none">
        @for (item of menuItems; track $index) {
            @if (authStateService.canAccess(item.permissionKey)) {
                <li>
                    <a
                        class="menu-item"
                        [routerLink]="item.route"
                        [customRouteActive]="item.route"
                        [tuiHint]="tip"
                        tuiHintAppearance="onDark"
                    >
                        <i class="bx {{ item.bxIcon }}"></i>
                        @if (!isSmall) {
                            <span>{{ item.label }}</span>
                        }
                    </a>
                </li>
                <ng-template #tip>
                    <kbd class="kbd">{{ item.shortcut ? item.shortcut : $index + 1 }}</kbd>
                </ng-template>
            }
        }
    </ul>

    <div class="join fixed bottom-4 left-2 mt-4">
        <button (click)="showDialog(dialogTpl)" class="btn btn-warning join-item rounded-l-full">
            <i class="bx bx-message-rounded-dots"></i>
            @if (!isSmall) {
                <span>Feedback</span>
            }
        </button>
        <button
            routerLink="{{ '/' + dashboardRoutes.roadmap.path }}"
            title="Roadmap"
            class="btn btn-warning join-item rounded-r-full border-0"
        >
            <i class="bx bx-trending-up"></i>
        </button>
    </div>

    <ng-template #dialogTpl let-observer>
        <app-feedback-dialog (done)="onDone()"></app-feedback-dialog>
    </ng-template>
</div>
