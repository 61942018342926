import { Component, inject } from '@angular/core'
import { TaigaUiImports } from '@core/ui/taiga-ui-imports'
import { ReleaseNote } from '@modules/update/models/release-note.model'
import { TuiDialogContext } from '@taiga-ui/core'
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus'
import { ReleaseNoteCardComponent } from '../release-note-card/release-note-card.component'

@Component({
    selector: 'app-release-note-list-dialog',
    standalone: true,
    imports: [...TaigaUiImports, ReleaseNoteCardComponent],
    templateUrl: './release-note-list-dialog.component.html',
    styleUrl: './release-note-list-dialog.component.scss',
})
export class ReleaseNoteListDialogComponent {
    private readonly context =
        inject<TuiDialogContext<ReleaseNote[], ReleaseNote[]>>(POLYMORPHEUS_CONTEXT)

    get releaseNotes(): ReleaseNote[] | null {
        return this.context.data
    }
}
