import { Injectable, inject } from '@angular/core'
import { AppStateService } from '@core/states/app-state.service'
import { SimpleStore } from '@core/states/simple-store'
import { AuthStateService } from '@modules/auth/states/auth-state.service'
import dayjs from 'dayjs'
import { interval, switchMap, tap, timer } from 'rxjs'
import { Journal } from '../models/journal.model'
import { JournalApiService } from '../services/journal-api.service'

const INTERVAL_MS = 4 * 60 * 60 * 1000 // 4 hours in milliseconds
const BUTTON_SHOW_DURATION_MS = 5 * 60 * 1000 // 5 minutes in milliseconds

export interface JournalState {
    isEnabled: boolean
    isCreated: boolean
    showButton: boolean
    journal: Journal | null
}

const initialState: JournalState = {
    isEnabled: false,
    isCreated: false,
    showButton: false,
    journal: null,
}

@Injectable({
    providedIn: 'root',
})
export class JournalStateService extends SimpleStore<JournalState> {
    private journalApiService = inject(JournalApiService)
    private authStateService = inject(AuthStateService)
    private appStateService = inject(AppStateService)

    private initialized = false

    constructor() {
        super(initialState)
    }

    init(delay = 3000) {
        if (this.initialized) return
        if (this.authStateService.isOwner()) return

        // these are non-priority, so we can delay them
        timer(delay).subscribe({
            next: () => {
                this.setState({ isEnabled: this.getIsDailyUpdateEnabled() })
                this.checkIsJournalCreatedByUserToday()
                this.continueCheckingAtSpecifiedTime()
                this.continueCheckingIsCreatedAtInterval()
                this.hideButtonAfterFiveMinutes()
                this.initialized = true
            },
        })
    }

    submitJournal(
        description: string,
        challenges: string,
        questions: string,
        logoutAfterSubmit: boolean,
    ) {
        return this.journalApiService.create({ description, challenges, questions }).pipe(
            tap(({ data }) => {
                this.setState({ journal: data, showButton: false })
                if (logoutAfterSubmit) this.authStateService.logout()
            }),
        )
    }

    private checkIsJournalCreatedByUserToday() {
        this.journalApiService.getIsJournalCreated(new Date()).subscribe({
            next: ({ data: isCreated }) => {
                this.setState({ isCreated })
            },
        })
    }

    private getIsDailyUpdateEnabled() {
        return (
            this.appStateService.getState().organization?.enableDailyUpdate &&
            !this.authStateService.isOwner()
        )
    }

    private hideButtonAfterFiveMinutes() {
        this.select('showButton')
            .pipe(switchMap((showButton) => timer(BUTTON_SHOW_DURATION_MS)))
            .subscribe({
                next: () => {
                    this.setState({ showButton: false })
                },
            })
    }

    private continueCheckingIsCreatedAtInterval() {
        interval(INTERVAL_MS)
            .pipe(switchMap(() => this.journalApiService.getIsJournalCreated(new Date())))
            .subscribe({
                next: ({ data: isCreated }) => {
                    this.setState({ isCreated, showButton: !isCreated })
                },
            })
    }

    private continueCheckingAtSpecifiedTime() {
        const today4pm = dayjs
            .utc()
            .set('hour', 16)
            .set('minute', 0)
            .set('second', 0)
            .set('millisecond', 0)

        timer(today4pm.toDate())
            .pipe(switchMap(() => this.journalApiService.getIsJournalCreated(new Date())))
            .subscribe({
                next: ({ data: isCreated }) => {
                    this.setState({ isCreated, showButton: !isCreated })
                },
            })
    }
}
