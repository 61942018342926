import { AsyncPipe, NgClass } from '@angular/common'
import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { Router, RouterLink, RouterLinkActive } from '@angular/router'
import { CustomRouterActiveDirective } from '@core/directives/custom-route-active.directive'
import { AppStateService } from '@core/states/app-state.service'
import { AuthStateService } from '@modules/auth/states/auth-state.service'
import { FeedbackDialogComponent } from '@modules/feedback/components/feedback-dialog/feedback-dialog.component'
import { NavbarLogoComponent } from '@modules/navbar/components/navbar-logo/navbar-logo.component'
import {
    SidebarMenuItem,
    highLevelUserMenu,
    lowLevelUserMenu,
    ownerUserMenu,
} from '@modules/sidebar/models/sidebar.model'
import { Permission } from '@modules/user/model/user.interface'
import { DashboardRoutes, dashboardRoutes } from '@pages/dashboard/dashboard.routes'
import { TuiDialogContext, TuiDialogService, TuiHintModule } from '@taiga-ui/core'
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus'
import { Hotkey, HotkeysService } from 'angular2-hotkeys'
import { Subscription } from 'rxjs'

@Component({
    selector: 'app-user-sidebar',
    templateUrl: './user-sidebar.component.html',
    styleUrls: ['./user-sidebar.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        RouterLink,
        RouterLinkActive,
        FormsModule,
        ReactiveFormsModule,
        AsyncPipe,
        FeedbackDialogComponent,
        NavbarLogoComponent,
        TuiHintModule,
        CustomRouterActiveDirective,
    ],
})
export class UserSidebarComponent implements OnInit, OnDestroy {
    appStateService = inject(AppStateService)
    authStateService = inject(AuthStateService)
    private readonly dialogs = inject(TuiDialogService)
    private router = inject(Router)
    private hotkeysService = inject(HotkeysService)

    @Input() isSmall = false
    @Input() isDark = false

    menuItems: SidebarMenuItem[] = []

    readonly dashboardRoutes: DashboardRoutes = dashboardRoutes
    readonly Permission = Permission

    private closeDialogSubscription: Subscription
    private hotkeys: Hotkey[] = []

    constructor() {
        if (this.authStateService.isOwner()) {
            this.menuItems = ownerUserMenu
        } else if (this.authStateService.isLowLevelUser()) {
            this.menuItems = lowLevelUserMenu
        } else if (this.authStateService.isHighLevelUser()) {
            this.menuItems = highLevelUserMenu
        } else {
            this.menuItems = []
        }
    }

    ngOnInit() {
        this.hotkeys = this.getRouteShortcuts()
        this.hotkeysService.add(this.hotkeys)
    }

    ngOnDestroy(): void {
        this.onDone()
        this.hotkeysService.remove(this.hotkeys)
    }

    onDone() {
        this.closeDialogSubscription?.unsubscribe()
    }

    showDialog(content: PolymorpheusContent<TuiDialogContext>) {
        this.closeDialogSubscription = this.dialogs.open<number>(content, { size: 'l' }).subscribe({
            next: (res) => {
                console.log('Dialog closed', res)
            },
        })
    }

    private getRouteShortcuts(): Hotkey[] {
        return this.menuItems.map((menuItem, i) => {
            const key = menuItem.shortcut ? menuItem.shortcut : String(i + 1)
            return new Hotkey(key, (event: KeyboardEvent): boolean => {
                this.router.navigate([menuItem.route])
                return false
            })
        })
    }
}
