import { Component, inject } from '@angular/core'
import { Idle } from '@ng-idle/core'
import { TuiDialogContext } from '@taiga-ui/core'
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus'

@Component({
    selector: 'app-idle-warning',
    standalone: true,
    imports: [],
    templateUrl: './idle-warning.component.html',
    styleUrls: ['./idle-warning.component.scss'],
})
export class IdleWarningComponent {
    private readonly context = inject<TuiDialogContext<boolean, number>>(POLYMORPHEUS_CONTEXT)
    private idle = inject(Idle)

    countdown = this.idle.getTimeout()

    constructor() {
        this.idle.onTimeoutWarning.subscribe((seconds) => {
            if (!seconds) {
                this.countdown = 0
                this.context.completeWith(true)
            } else {
                this.countdown = seconds
            }
        })

        this.idle.onIdleEnd.subscribe(() => {
            this.context.completeWith(false)
        })
    }
}
