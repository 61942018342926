import { AsyncPipe, CommonModule } from '@angular/common'
import { Component, OnInit, inject } from '@angular/core'
import { Router, RouterLink } from '@angular/router'
import { ThemeService } from '@core/services/theme.service'
import { ReleaseNoteListStateService } from '@modules/update/states/release-note-list-state.service'
import { AuthRoutes, getAuthRoutes } from '@pages/auth/auth.routes'
import { HomeRoutes, getHomeRoutes } from '@pages/home/home.routes'
import { ReleaseNoteCarouselComponent } from '../../../update/components/release-note-carousel/release-note-carousel.component'

@Component({
    selector: 'app-public-navbar',
    templateUrl: './public-navbar.component.html',
    styleUrls: ['./public-navbar.component.scss'],
    standalone: true,
    imports: [RouterLink, AsyncPipe, CommonModule, ReleaseNoteCarouselComponent],
})
export class PublicNavbarComponent implements OnInit {
    private router = inject(Router)
    releaseNoteListStateService = inject(ReleaseNoteListStateService)
    themeService = inject(ThemeService)

    isAdvNavbar = false

    readonly authRoutes: AuthRoutes = getAuthRoutes()

    ngOnInit(): void {}
}
