import { HttpClient } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { ApiService } from '@core/services/api.service'
import { UserLog, UserLogDto } from '../models/user-log.model'

@Injectable({
    providedIn: 'root',
})
export class UserLogApiService extends ApiService<UserLogDto, UserLog> {
    protected http: HttpClient

    constructor() {
        const http = inject(HttpClient)

        super(http, 'v2/user-logs')

        this.http = http
    }
}
