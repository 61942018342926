<div class="p-2">
    <app-navbar-logo [isSmall]="isSmall" [isDark]="isDark"></app-navbar-logo>
</div>

<ul class="menu rounded-none">
    <li>
        <a
            class="menu-item"
            [routerLink]="adminRoutes.index.path"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            [tuiHint]="tip1"
            tuiHintAppearance="onDark"
        >
            <i class="bx bx-home"></i>
            <span *ngIf="!isSmall">Home</span>
        </a>
    </li>
    <ng-template #tip1>
        <kbd class="kbd">1</kbd>
    </ng-template>
    <li>
        <a
            class="menu-item"
            [routerLink]="adminRoutes.users.path"
            routerLinkActive="active"
            [tuiHint]="tip2"
            tuiHintAppearance="onDark"
        >
            <i class="bx bx-user"></i>
            <span *ngIf="!isSmall">User</span>
        </a>
    </li>
    <ng-template #tip2>
        <kbd class="kbd">2</kbd>
    </ng-template>
    <li>
        <a
            class="menu-item"
            [routerLink]="adminRoutes.feedbacks.path"
            routerLinkActive="active"
            [tuiHint]="tip3"
            tuiHintAppearance="onDark"
        >
            <i class="bx bx-conversation"></i>
            <span *ngIf="!isSmall">Feedback</span>
        </a>
    </li>
    <ng-template #tip3>
        <kbd class="kbd">3</kbd>
    </ng-template>
    <li>
        <a
            class="menu-item"
            [routerLink]="adminRoutes.plans.path"
            routerLinkActive="active"
            [tuiHint]="tip4"
            tuiHintAppearance="onDark"
        >
            <i class="bx bx-table"></i>
            <span *ngIf="!isSmall">Plans</span>
        </a>
    </li>
    <ng-template #tip4>
        <kbd class="kbd">4</kbd>
    </ng-template>
    <li>
        <a
            class="menu-item"
            [routerLink]="adminRoutes.organizations.path"
            routerLinkActive="active"
            [tuiHint]="tip5"
            tuiHintAppearance="onDark"
        >
            <i class="bx bx-group"></i>
            <span *ngIf="!isSmall">Organizations</span>
        </a>
    </li>
    <ng-template #tip5>
        <kbd class="kbd">5</kbd>
    </ng-template>
    <li>
        <a
            class="menu-item"
            [routerLink]="adminRoutes.releaseNotes.path"
            routerLinkActive="active"
            [tuiHint]="tip6"
            tuiHintAppearance="onDark"
        >
            <i class="bx bx-bot"></i>
            <span *ngIf="!isSmall">Release Notes</span>
        </a>
    </li>
    <ng-template #tip6>
        <kbd class="kbd">6</kbd>
    </ng-template>

    <ng-template #tip6>
        <kbd class="kbd">6</kbd>
    </ng-template>
</ul>
