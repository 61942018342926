import { CommonModule } from '@angular/common'
import { Component, inject } from '@angular/core'
import { NavigationEnd, NavigationStart, Router, RouterModule } from '@angular/router'
import { ThemeService } from '@core/services/theme.service'
import { AppStateService } from '@core/states/app-state.service'
import { TaigaUiImports } from '@core/ui/taiga-ui-imports'
import { AuthStateService } from '@modules/auth/states/auth-state.service'
import { AdminNavbarComponent } from '@modules/navbar/components/admin-navbar/admin-navbar.component'
import { AdminSidebarComponent } from '@modules/sidebar/components/admin-sidebar/admin-sidebar.component'
import { AdminRoutes, getAdminRoutes } from '@pages/admin/admin.routes'

@Component({
    selector: 'app-layout-admin',
    standalone: true,
    templateUrl: './layout-admin.component.html',
    styleUrls: ['./layout-admin.component.scss'],
    imports: [
        CommonModule,
        ...TaigaUiImports,
        RouterModule,
        AdminNavbarComponent,
        AdminSidebarComponent,
    ],
})
export class LayoutAdminComponent {
    private authStateService = inject(AuthStateService)
    private router = inject(Router)
    appStateService = inject(AppStateService)
    themeService = inject(ThemeService)

    readonly adminRoutes: AdminRoutes = getAdminRoutes()
    items: { label: string; routerLink: string; icon?: string }[] = []
    currentRoute = ''

    ngOnInit(): void {
        this.initBreadcrumbMenuItems(this.router.url)
        this.updateBreadcrumbMenuItems()
    }

    initBreadcrumbMenuItems(url: string) {
        this.items = [
            {
                label: 'Dashboard',
                routerLink: this.adminRoutes.index.path,
                icon: 'bx bx-home',
            },
        ]

        if (url.includes('users')) {
            this.items.push({
                label: 'Users',
                routerLink: this.adminRoutes.users.path,
            })
        }

        if (url.includes('feedbacks')) {
            this.items.push({
                label: 'Feedbacks',
                routerLink: this.adminRoutes.feedbacks.path,
            })
        }

        if (url.includes('plans')) {
            this.items.push({
                label: 'Plans',
                routerLink: this.adminRoutes.plans.path,
            })
        }

        if (url.includes('organization')) {
            this.items.push({
                label: 'Organizations',
                routerLink: this.adminRoutes.organizations.path,
            })
        }

        if (url.includes('updates')) {
            this.items.push({
                label: 'Updates',
                routerLink: this.adminRoutes.createReleaseNote.path,
            })
        }
    }

    updateBreadcrumbMenuItems() {
        this.router.events.subscribe({
            next: (event: any) => {
                if (event instanceof NavigationStart) {
                    // Show progress spinner or progress bar
                    this.initBreadcrumbMenuItems(event.url)
                }
                if (event instanceof NavigationEnd) {
                    // Hide progress spinner or progress bar
                    this.currentRoute = event.url
                }
            },
        })
    }

    onItemClicked(event: any) {
        console.log('event')
        console.log(event)
    }
}
