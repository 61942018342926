@if (themeService.isDark$ | async) {
    <tui-theme-night></tui-theme-night>
}

<tui-root [tuiMode]="(themeService.isDark$ | async) ? 'onDark' : null">
    @switch (pageLayoutService.layout$ | async) {
        @case (PageLayout.Default) {
            <app-layout-default>
                <router-outlet></router-outlet>
            </app-layout-default>
        }
        @case (PageLayout.Center) {
            <app-layout-centered>
                <router-outlet></router-outlet>
            </app-layout-centered>
        }
        @case (PageLayout.Sidebar) {
            <app-layout-sidebar>
                <router-outlet></router-outlet>
            </app-layout-sidebar>
        }
        @case (PageLayout.Advertiser) {
            <app-layout-advertiser>
                <router-outlet></router-outlet>
            </app-layout-advertiser>
        }
        @case (PageLayout.Plan) {
            <app-layout-plans>
                <router-outlet></router-outlet>
            </app-layout-plans>
        }
        @case (PageLayout.Admin) {
            <app-layout-admin>
                <router-outlet></router-outlet>
            </app-layout-admin>
        }
        @case (PageLayout.Blank) {
            <router-outlet></router-outlet>
        }
        @default {
            <app-layout-default>
                <router-outlet></router-outlet>
            </app-layout-default>
        }
    }

    <ng-container ngProjectAs="tuiOverContent">
        <!-- Content over app content -->
    </ng-container>
    <ng-container ngProjectAs="tuiOverDialogs">
        <!-- Content over dialogs -->
    </ng-container>
    <ng-container ngProjectAs="tuiOverAlerts">
        <!-- Content over alerts -->
    </ng-container>
    <ng-container ngProjectAs="tuiOverPortals">
        <!-- Content over dropdowns -->
    </ng-container>
    <ng-container ngProjectAs="tuiOverHints">
        <!-- Content over hints -->
    </ng-container>

    <!-- Add RotateScreenComponent here -->
    <app-rotate-screen [visible]="showRotateScreen"></app-rotate-screen>
</tui-root>
