<div class="w-[90vw] lg:w-[75vw]">
    <h1 class="mt-2 text-2xl">Release Notes</h1>

    @if (releaseNotes) {
        <div class="mt-4 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
            @for (releaseNote of releaseNotes; track releaseNote) {
                <app-release-note-card [releaseNote]="releaseNote"></app-release-note-card>
            }
        </div>
    } @else {
        <div class="flex h-[40vh] items-center justify-center text-2xl">No Data</div>
    }
</div>
