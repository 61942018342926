import { AuthStateService } from '@modules/auth/states/auth-state.service'
import { Observable, catchError, of } from 'rxjs'

export function appInitializerFactory(authStateService: AuthStateService): () => Observable<any> {
    return () =>
        authStateService.refreshAccessToken().pipe(
            catchError((err) => {
                console.error('Error on APP_INIIT', err)

                return of()
            }),
        )
}
