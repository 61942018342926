import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { PomodoroService } from '@core/services/pomodoro.service'
import { TaigaUiImports } from '@core/ui/taiga-ui-imports'
import { Subscription } from 'rxjs'
import { Todo } from '../models/todo.model'
import { PomodoroTimerComponent } from '../pomodoro-timer/pomodoro-timer.component'
import { TodoStateService } from '../states/todo-state.service'

interface ITodo extends Todo {
    type?: 'todo' | 'requirement'
}
@Component({
    selector: 'app-todo-panel',
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, ...TaigaUiImports, PomodoroTimerComponent],
    templateUrl: './todo-panel.component.html',
    styleUrls: ['./todo-panel.component.scss'],
})
export class TodoPanelComponent {
    selectedTodo: ITodo = null
    selectedTodoSubscription: Subscription

    constructor(
        public todoStateService: TodoStateService,
        private pomodoroService: PomodoroService,
    ) {}

    ngOnInit(): void {
        this.selectedTodoSubscription = this.pomodoroService.selectedTodo$.subscribe((todo) => {
            this.selectedTodo = todo
        })
    }

    ngOnDestroy(): void {
        this.selectedTodoSubscription.unsubscribe()
    }

    toggleTodoStatus(todo: Todo) {
        this.pomodoroService.toggleTodoStatus(todo)
    }

    selectTodo(todo: Todo | null): void {
        this.pomodoroService.selectTodo({ ...todo, type: 'todo' })
    }
}
