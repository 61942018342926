<div class="navbar bg-base-200">
    <div class="flex-1">
        <a class="btn btn-ghost text-xl normal-case" (click)="toggleSidebar()">
            <i class="bx bx-menu text-2xl text-base-content"></i>
        </a>
    </div>
    <div class="flex-none">
        <ul class="menu menu-horizontal px-1">
            <li>
                <span class="pt-2 opacity-60">
                    {{ date$ | async | date: 'EEEE, MMMM d, y h:mm a' }}
                </span>
            </li>
        </ul>

        <div class="dropdown dropdown-end">
            <label
                *tuiLet="appStateService.select('isDarkMode') | async as isDarkMode"
                (click)="toggleTheme()"
                tabindex="0"
                class="btn btn-circle btn-ghost"
            >
                @if (!isDarkMode) {
                    <i class="bx bx-moon text-2xl"></i>
                }
                @if (isDarkMode) {
                    <i class="bx bx-sun text-2xl"></i>
                }
            </label>
        </div>

        <div class="dropdown dropdown-end ml-8">
            <label tabindex="0" class="avatar btn btn-circle btn-ghost">
                <div class="w-10 rounded-full">
                    <img
                        useFallbackImage
                        [src]="(authStateService.select('user') | async).profileImage"
                    />
                </div>
            </label>
            <ul
                tabindex="0"
                class="menu dropdown-content menu-sm z-[1] mt-3 w-52 rounded-box bg-base-100 p-2 shadow"
            >
                <li>
                    <a [routerLink]="dashboardRoutes.index.path">User Dashboard</a>
                </li>
                <li>
                    <a (click)="logout()">Logout</a>
                </li>
            </ul>
        </div>
    </div>
</div>
