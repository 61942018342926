import { AsyncPipe } from '@angular/common'
import { Component, HostListener, Injector, OnInit, inject } from '@angular/core'
import { RouterOutlet } from '@angular/router'
import { TokenSharingService } from '@core/services/jwt/token-sharing.service'
import { ThemeService } from '@core/services/theme.service'
import { UsageStateService } from '@core/states/usage-state.service'
import { AuthStateService } from '@modules/auth/states/auth-state.service'
import { ChatService } from '@modules/chat/services/chat.service'
import { ContentStateService } from '@modules/content/states/content-state.service'
import { FeedbackStateService } from '@modules/feedback/states/feedback-state.service'
import { IdleWarningComponent } from '@modules/idle/component/idle-warning/idle-warning.component'
import { JournalStateService } from '@modules/journal/states/journal-state.service'
import { LayoutAdminComponent } from '@modules/layout/layout-admin/layout-admin.component'
import { LayoutAdvertiserComponent } from '@modules/layout/layout-advertiser/layout-advertiser.component'
import { LayoutCenteredComponent } from '@modules/layout/layout-centered/layout-centered.component'
import { LayoutDefaultComponent } from '@modules/layout/layout-default/layout-default.component'
import { LayoutSidebarComponent } from '@modules/layout/layout-sidebar/layout-sidebar.component'
import { PageLayout } from '@modules/layout/page-layout.enum'
import { PageLayoutService } from '@modules/layout/page-layout.service'
import { NotificationStateService } from '@modules/navbar/services/notification-state.service'
import { ResearchStateService } from '@modules/research/states/research-state.service'
import { SiteStateService } from '@modules/sites/states/site-state.service'
import { TodoStateService } from '@modules/todo/states/todo-state.service'
import { ReleaseNoteListDialogComponent } from '@modules/update/components/release-note-list-dialog/release-note-list-dialog.component'
import { ReleaseNote } from '@modules/update/models/release-note.model'
import { ReleaseNoteApiService } from '@modules/update/services/release-note-api.service'
import { UserListStateService } from '@modules/user/states/user-list-state.service'
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core'
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'
import { ApplicationUpdatesComponent } from '@pages/admin/application-updates/application-updates.component'
import { TUI_DIALOG_CLOSES_ON_BACK } from '@taiga-ui/cdk'
import {
    TUI_SANITIZER,
    TuiAlertModule,
    TuiDialogModule,
    TuiDialogService,
    TuiModeModule,
    TuiRootModule,
    TuiThemeNightModule,
} from '@taiga-ui/core'
import { NgDompurifySanitizer } from '@tinkoff/ng-dompurify'
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus'
import { delay, filter, of } from 'rxjs'
import { RotateScreenComponent } from './core/components/rotate-screen/rotate-screen.component'
import { LayoutPlansComponent } from './modules/layout/layout-plans/layout-plans.component'

const SECONDS_TILL_IDLE = 20 * 60
const SECONDS_TILL_TIMEOUT = 120

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    providers: [
        { provide: TUI_SANITIZER, useClass: NgDompurifySanitizer },
        {
            provide: TUI_DIALOG_CLOSES_ON_BACK,
            useValue: of(true),
        },
    ],
    imports: [
        AsyncPipe,
        LayoutAdminComponent,
        LayoutAdvertiserComponent,
        LayoutPlansComponent,
        LayoutCenteredComponent,
        LayoutDefaultComponent,
        LayoutSidebarComponent,
        RouterOutlet,
        TuiAlertModule,
        TuiDialogModule,
        TuiModeModule,
        TuiRootModule,
        TuiThemeNightModule,
        ApplicationUpdatesComponent,
        NgIdleKeepaliveModule,
        RotateScreenComponent,
    ],
})
export class AppComponent implements OnInit {
    private readonly injector = inject(Injector)
    private readonly dialog = inject(TuiDialogService)
    private authStateService = inject(AuthStateService)
    private contentStateService = inject(ContentStateService)
    private chatService = inject(ChatService)
    private feedbackStateService = inject(FeedbackStateService)
    private idle = inject(Idle)
    private journalStateService = inject(JournalStateService)
    private notificationStateService = inject(NotificationStateService)
    private siteStateService = inject(SiteStateService)
    private researchStateService = inject(ResearchStateService)
    private releaseNoteApiService = inject(ReleaseNoteApiService)
    private todoStateService = inject(TodoStateService)
    private tokenSharingService = inject(TokenSharingService)
    private userListStateService = inject(UserListStateService)
    pageLayoutService = inject(PageLayoutService)
    themeService = inject(ThemeService)
    private usageStateService = inject(UsageStateService)

    readonly PageLayout = PageLayout
    isMobileScreen = false
    showRotateScreen = false

    @HostListener('document:keydown.escape', ['$event'])
    handleEscape(event: KeyboardEvent) {
        if (document.fullscreenElement) {
            // Prevent the default behavior of the ESC key, specially for Safari
            // TODO: check whether it has any negative impact on the app
            event.preventDefault()
        }
    }
    @HostListener('window:resize', ['$event'])
    onResize(event: unknown) {
        this.checkScreenSize()
    }

    constructor() {
        this.tokenSharingService.init()
        this.themeService.setAutoTheme()

        this.authStateService
            .select('isLoggedIn')
            .pipe(
                filter((isLoggedIn) => isLoggedIn),
                delay(500),
            )
            .subscribe({
                next: () => this.initAfterLogin(),
            })
    }

    ngOnInit(): void {
        this.checkScreenSize()
    }

    private checkScreenSize() {
        this.isMobileScreen = window.innerWidth <= 767
        if (this.isMobileScreen) {
            this.showRotateScreen = true
            setTimeout(() => {
                this.showRotateScreen = false
            }, 5000) // Hide after 3 seconds
        }
    }

    private initAfterLogin() {
        this.siteStateService.init()
        this.researchStateService.init()
        this.contentStateService.init()
        this.feedbackStateService.init()
        this.userListStateService.init()
        this.todoStateService.init()
        this.notificationStateService.init()
        this.journalStateService.init()
        this.chatService.init()
        this.getReleaseNotesForUser()
        this.initIdleWatcher()
        this.usageStateService.init()
    }

    private showReleaseNotes(releaseNotes: ReleaseNote[]) {
        this.dialog
            .open(new PolymorpheusComponent(ReleaseNoteListDialogComponent, this.injector), {
                data: releaseNotes,
                dismissible: true,
                label: 'Release Notes',
                size: 'auto',
            })
            .subscribe({})
    }

    private initIdleWatcher() {
        this.idle.setIdle(SECONDS_TILL_IDLE)
        this.idle.setTimeout(SECONDS_TILL_TIMEOUT)
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES)
        this.idle.onIdleStart.subscribe({ next: () => this.showIdleWarning() })
        this.idle.onTimeout.subscribe({ next: () => this.authStateService.logout() })
        this.idle.watch()
    }

    private getReleaseNotesForUser() {
        const { user } = this.authStateService.getState()
        this.releaseNoteApiService.getReleaseNotesForUser(user?.id).subscribe({
            next: ({ data }) => {
                if (data.length) {
                    this.showReleaseNotes(data)
                }
            },
            error: (error) => {
                console.error('Error fetching release notes:', error)
            },
        })
    }

    private showIdleWarning() {
        const dialog = this.dialog.open(
            new PolymorpheusComponent(IdleWarningComponent, this.injector),
            {
                data: this.idle.getTimeout(),
                dismissible: true,
                label: 'You are idle',
                size: 'auto',
            },
        )

        dialog.subscribe()
    }
}
