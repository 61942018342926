<app-pomodoro-timer
    #pomodoroTimer
    [todo]="selectedTodo"
    (setSelectedTodo)="selectTodo($event)"
></app-pomodoro-timer>
<div class="flex flex-col gap-2">
    <input
        [formControl]="todoStateService.todoTextControl"
        placeholder="Enter to do"
        (keyup.enter)="todoStateService.saveTodo()"
        class="w-full rounded-md border border-gray-300 p-2"
    />
    <button (click)="todoStateService.saveTodo()" class="btn btn-secondary btn-sm btn-block">
        @if (todoStateService.select('loading') | async) {
            <span class="loading loading-spinner loading-sm mr-2"></span>
        }
        Save
    </button>
</div>

<div class="max-h-80 overflow-auto">
    @for (item of todoStateService.select('todos') | async; track item) {
        <div
            class="single-todo my-2 flex items-center justify-between gap-2 p-2 text-sm hover:rounded-lg hover:bg-base-200"
        >
            <input
                type="checkbox"
                checked="{{ item.completed ? 'checked' : '' }}"
                class="checkbox checkbox-sm"
                (change)="toggleTodoStatus(item)"
            />

            <p
                class="prose {{ item.completed ? 'opacity-60 line-through' : '' }}"
                [innerHTML]="item.text"
            ></p>

            <div class="arrow-up tooltip" (click)="selectTodo(item)">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="20px"
                    viewBox="0 0 24 24"
                    width="20px"
                    fill="#ffffff"
                >
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path
                        d="M13 19V7.83l4.88 4.88c.39.39 1.03.39 1.42 0 .39-.39.39-1.02 0-1.41l-6.59-6.59c-.39-.39-1.02-.39-1.41 0l-6.6 6.58c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L11 7.83V19c0 .55.45 1 1 1s1-.45 1-1z"
                    />
                </svg>
                <span class="tooltiptext">Add Pomodoro Session</span>
            </div>

            <div>
                <i
                    class="bx bx-trash cursor-pointer"
                    (click)="todoStateService.deleteTodo(item)"
                ></i>
            </div>
        </div>
    }
</div>
