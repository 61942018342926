import { AsyncPipe, CommonModule } from '@angular/common'
import { Component, inject } from '@angular/core'
import { NavigationEnd, NavigationStart, Router, RouterModule, RouterOutlet } from '@angular/router'
import { AppStateService } from '@core/states/app-state.service'
import { TaigaUiImports } from '@core/ui/taiga-ui-imports'
import { AuthStateService } from '@modules/auth/states/auth-state.service'
import { FeedbackApiService } from '@modules/feedback/services/feedback-api.service'
import { AdvertiserSidebarComponent } from '@modules/sidebar/components/advertiser-sidebar/advertiser-sidebar.component'
import { AdvertiserRoutes, getAdvertiserRoutes } from '@pages/advertiser/advertiser.routes'
import { UserNavbarComponent } from '../../navbar/components/user-navbar/user-navbar.component'

@Component({
    standalone: true,
    selector: 'app-layout-advertiser',
    templateUrl: './layout-advertiser.component.html',
    styleUrls: ['./layout-advertiser.component.scss'],
    imports: [
        ...TaigaUiImports,
        AdvertiserSidebarComponent,
        AsyncPipe,
        CommonModule,
        RouterModule,
        RouterOutlet,
        UserNavbarComponent,
    ],
})
export class LayoutAdvertiserComponent {
    private authStateService = inject(AuthStateService)
    private router = inject(Router)
    appStateService = inject(AppStateService)
    feedbackService = inject(FeedbackApiService)

    items: { label: string; routerLink: string; icon?: string }[] = []
    currentRoute = ''
    proPlan = false
    isDark$ = this.appStateService.select('isDarkMode')

    readonly advertiserRoutes: AdvertiserRoutes = getAdvertiserRoutes()

    ngOnInit(): void {
        this.initBreadcrumbMenuItems(this.router.url)
        this.updateBreadcrumbMenuItems()
        const userOrganization = this.authStateService.getUser().organization
        this.proPlan =
            userOrganization.isTrialing === false && userOrganization.purchaseDate !== null
    }

    initBreadcrumbMenuItems(url: string) {
        this.items = [
            {
                label: 'Dashboard',
                routerLink: this.advertiserRoutes.index.path,
                icon: 'bx bx-home',
            },
        ]

        if (url.includes('profile')) {
            this.items.push({
                label: 'Profile',
                routerLink: this.advertiserRoutes.profile.path,
            })
        }

        // if( url.includes('advertise') ) {
        //   this.items.push({
        //     label: 'Advertise', routerLink: '/dashboard/advertise'
        //   })
        // }
    }

    updateBreadcrumbMenuItems() {
        this.router.events.subscribe({
            next: (event: any) => {
                if (event instanceof NavigationStart) {
                    // Show progress spinner or progress bar
                    this.initBreadcrumbMenuItems(event.url)
                }

                if (event instanceof NavigationEnd) {
                    // Hide progress spinner or progress bar
                    this.currentRoute = event.url
                }
            },
        })
    }

    onItemClicked(event: any) {
        console.log('event')
        console.log(event)
    }
}
