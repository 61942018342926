<div class="flex">
    <div class="flex-1 bg-base-200">
        <nav class="sticky top-0 z-30">
            <app-user-navbar
                [currentPage]="planRoutes.index.path"
                class="border-b border-solid border-slate-300"
            ></app-user-navbar>
        </nav>

        <div class="flex">
            <main class="block min-h-[900px] w-full bg-base-200 p-4 pb-20">
                <ng-content></ng-content>
            </main>
        </div>
    </div>
</div>
