import { Route } from '@angular/router'
import { AuthGuard } from '@core/guards/auth.guard'
import { PageLayout } from '@modules/layout/page-layout.enum'
import { setLayout } from '@modules/layout/set-layout.resolver'

export type ShopRoutes = {
    index: Route
}

export const shopRoutes: ShopRoutes = {
    index: {
        path: 'shop',
        title: 'Shop',
        resolve: { layout: setLayout(PageLayout.Plan) },
        canActivate: [AuthGuard],
        loadComponent: () => import('./shop.component').then((m) => m.ShopComponent),
    },
}
