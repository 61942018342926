import { Route } from '@angular/router'
import { PageLayout } from '@modules/layout/page-layout.enum'
import { setLayout } from '@modules/layout/set-layout.resolver'

export type HomeRoutes = {
    index: Route
    redirect: Route
}

export function getHomeRoutes(): HomeRoutes {
    return {
        index: {
            path: '',
            title: 'Home',
            resolve: { layout: setLayout(PageLayout.Default) },
            loadComponent: () => import('./home.component').then((m) => m.HomeComponent),
        },
        redirect: {
            path: 'home',
            redirectTo: '',
            pathMatch: 'full',
        },
    }
}
