<div class="p-2">
    <app-navbar-logo [isSmall]="isSmall" [isDark]="isDark"></app-navbar-logo>
</div>

<ul class="menu rounded-none">
    @if (authStateService.isHighLevelUser()) {
        <li>
            <a
                class="menu-item"
                [routerLink]="advertiserRoutes.index.path"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
            >
                <i class="bx bx-home"></i>
                @if (!isSmall) {
                    <span>Home</span>
                }
            </a>
        </li>
    }
    <li>
        <a
            class="menu-item"
            [routerLink]="advertiserRoutes.advertise.path"
            routerLinkActive="active"
        >
            <i class="bx bx-wallet"></i>
            @if (!isSmall) {
                <span>Advertise</span>
            }
        </a>
    </li>
    <li>
        <a class="menu-item" [routerLink]="advertiserRoutes.profile.path" routerLinkActive="active">
            <i class="bx bx-user-circle"></i>
            @if (!isSmall) {
                <span>Profile</span>
            }
        </a>
    </li>
</ul>

<a class="btn btn-warning fixed bottom-4 left-0 ml-2 rounded-full" (click)="showDialog(dialogTpl)">
    <i class="bx bx-message-rounded-dots"></i>
    @if (!isSmall) {
        <span>Feedback</span>
    }
</a>

<ng-template #dialogTpl let-observer>
    <app-feedback-dialog (done)="onDone()"></app-feedback-dialog>
</ng-template>
