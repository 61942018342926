import { Component, inject } from '@angular/core'
import { RouterModule } from '@angular/router'
import { AuthStateService } from '@modules/auth/states/auth-state.service'
import { PublicNavbarComponent } from '@modules/navbar/components/public-navbar/public-navbar.component'

@Component({
    selector: 'app-layout-default',
    standalone: true,
    templateUrl: './layout-default.component.html',
    styleUrls: ['./layout-default.component.scss'],
    imports: [RouterModule, PublicNavbarComponent],
})
export class LayoutDefaultComponent {
    private authStateService = inject(AuthStateService)

    isAdvertiserAuth: boolean

    ngOnInit(): void {
        if (this.authStateService.isAdvertiser()) {
            this.isAdvertiserAuth = true
        } else {
            this.isAdvertiserAuth = false
        }
    }
}
